import { useEffect, useState } from 'react';
// import { DayPicker, MonthPicker, YearPicker } from 'react-dropdown-date';
// import { useIntl } from 'react-intl';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// import { isValidNumber } from 'services/validation';

import './index.scss';
import { formatISO, getMonth, getYear } from 'date-fns';

function DateMonthYearPicker({ defaultValue, onChange, errors }) {
    const [selectedDate, setSelectedDate] = useState(null);

    // const intl = useIntl();

    useEffect(() => {
        if(!selectedDate) {
            return;
        }

        const formattedDate = formatISO(selectedDate, { format: 'extended', representation: 'date' });
        let [year, month, day] = formattedDate.split('-');

        onChange?.(formattedDate, false, { year, month, day });
    }, [selectedDate]);

    function range(size, startAt = 0) {
        return [...Array(size).keys()].map(i => i + startAt);
    }

    const years = range(getYear(new Date()), 1900, 1);
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    return (
        <div className="dmy-picker">
            <DatePicker
                className='dmy-input'
                name='inputDate'
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div
                    style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                        gap: '.5rem'
                    }}
                    >
                        <button type="button" className="dmy-month-change" style={{marginRight: 'auto'}} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                            {"<"}
                        </button>
                        <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) => changeYear(value)}
                        >
                            {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                            ))}
                        </select>

                        <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                            }
                        >
                            {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                            ))}
                        </select>

                        <button type="button" className="dmy-month-change" style={{marginLeft: 'auto'}} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                            {">"}
                        </button>
                    </div>
                )}
                closeOnScroll={false}
                shouldCloseOnSelect={false}
                selected={selectedDate ?? defaultValue ?? new Date()}
                onChange={(date) => setSelectedDate(date)}
            />

            {/* <DayPicker
                defaultValue={intl.formatMessage({ id: 'birthday.day-placeholder' })}
                year={date.year} // mandatory
                month={date.month} // mandatory
                endYearGiven // mandatory if end={} is given in YearPicker
                name="day"
                value={date.day} // mandatory
                onChange={(day) => {
                    // mandatory
                    setDate((prev) => ({ ...prev, day }));
                }}
                id="day"
                classes={`dropdown ${ errors?.day ? 'error' : '' }`}
                optionClasses="option"
            />

            <MonthPicker
                defaultValue={intl.formatMessage({ id: 'birthday.month-placeholder' })}

                // numeric // to get months as numbers
                endYearGiven // mandatory if end={} is given in YearPicker
                name="month"
                numeric
                year={date.year} // mandatory
                value={date.month} // mandatory
                onChange={(month) => {
                    // mandatory
                    setDate((prev) => ({ ...prev, month }));
                }}
                id="month"
                classes={`dropdown ${ errors?.month ? 'error' : '' }`}
                optionClasses="option"
            />

            <YearPicker
                defaultValue={intl.formatMessage({ id: 'birthday.year-placeholder' })}

                // start={1900} // default is 1900
                // end={2020} // default is current year
                name="year"
                reverse // default is ASCENDING
                value={date.year} // mandatory
                onChange={(year) => {
                    // mandatory
                    setDate((prev) => ({ ...prev, year }));
                }}
                id="year"
                classes={`dropdown ${ errors?.year ? 'error' : '' }`}
                optionClasses="option"
            /> */}
        </div>
    );
}

export default DateMonthYearPicker;
